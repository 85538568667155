import { makeAutoObservable, runInAction } from "mobx";

import { StatisticAffiliatesValue, StatisticAffiliatesPreviewValue } from "../model";
import { getFormatStatisticOffersAndAffiliatesValue } from "../utils";

import { StatisticsDaysApi } from "shared/api";
import { PaginationStore } from "shared/store/Pagination";
import { SortingStore } from "shared/store/Sorting";

const { getData } = StatisticsDaysApi();

export class StatisticDaysStore {
  pagination = new PaginationStore(10);
  sorting = new SortingStore("date", 3);
  data: StatisticAffiliatesValue[] | null = null;
  summary: StatisticAffiliatesValue | null = null;
  loading = true;

  constructor() {
    makeAutoObservable(this);
  }

  get formatData(): StatisticAffiliatesPreviewValue[] {
    if (this.data) {
      return this.data.map((item, i) => {
        const children = item.offers?.map((offer, j) =>
          getFormatStatisticOffersAndAffiliatesValue({
            ...offer,
            date: `(${offer.id}) ${offer.name}`,
            key: `day-offer-${item.id}-${offer.id}-${j}`,
          }),
        );
        return {
          ...getFormatStatisticOffersAndAffiliatesValue({
            ...item,
            key: `day-${item.id}-${i}`,
          }),
          children,
        };
      });
    }
    return [];
  }

  get formatSummary(): StatisticAffiliatesPreviewValue | undefined {
    if (this.summary) {
      return getFormatStatisticOffersAndAffiliatesValue({ ...this.summary, date: "Всего", key: "days-summary" });
    }
  }

  loadData = async (filters: Record<string, any>) => {
    this.loading = true;
    const result = await getData({
      ...filters,
      page: this.pagination.page,
      pageSize: this.pagination.pageSize,
      sortParam: this.sorting.sortParam,
      sortDirection: this.sorting.sortDirection || null,
    });
    runInAction(() => {
      if (result.isSuccess) {
        this.data = [...result.result.items];
        this.summary = this.data.length ? { ...result.result.summary } : null;
        this.pagination.updateTotalCount(result.result.pagination.totalCount);
      } else if (result.isError) {
        this.data = [];
        this.summary = null;
        this.pagination.updatePage(1);
        this.pagination.updateTotalCount(0);
      }
      this.loading = false;
    });
  };
}
