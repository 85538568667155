import { runInAction, makeAutoObservable } from "mobx";

import { DictionaryValue } from "../model";

import { DictionaryValueStore } from "./dictionaryValue";

import { StatisticsDictionaryApi } from "shared/api";
import { Result } from "shared/api/request";

const {
  getOffers,
  getTimeZone,
  getAffiliates,
  getOpponents,
  getOpponentsAffiliates,
  getCountries,
  getCurrencies,
  getOfferBrandAdvertisers,
} = StatisticsDictionaryApi();

export class StatisticsDictionaryStore {
  offers: DictionaryValue = null;
  timeZone: DictionaryValue = null;
  opponents: DictionaryValue = null;
  brandAdvertisers: DictionaryValue = null;
  affiliates = new DictionaryValueStore();
  opponentsAffiliates = new DictionaryValueStore();
  currencies: DictionaryValue = null;
  countries: DictionaryValue = null;

  constructor() {
    makeAutoObservable(this);
  }

  loadTimeZone = async () => {
    if (!this.timeZone) {
      const result: Result = await getTimeZone();
      runInAction(() => {
        if (result.isSuccess) {
          this.timeZone = [...result.result];
        } else {
          this.timeZone = [];
        }
      });
    }
  };

  loadOffers = async () => {
    if (!this.offers) {
      const result: Result = await getOffers();
      runInAction(() => {
        if (result.isSuccess) {
          this.offers = [...result.result];
        } else {
          this.offers = [];
        }
      });
    }
  };

  loadCurrencies = async () => {
    if (!this.currencies) {
      const result: Result = await getCurrencies();
      runInAction(() => {
        if (result.isSuccess) {
          this.currencies = [...result.result];
        } else {
          this.currencies = [];
        }
      });
    }
  };

  loadCountries = async () => {
    if (!this.countries) {
      const result: Result = await getCountries();
      runInAction(() => {
        if (result.isSuccess) {
          this.countries = result.result.length === 0 ? [{ value: "-1", label: "Все страны" }] : [...result.result];
        } else {
          this.countries = [];
        }
      });
    }
  };

  loadOpponents = async () => {
    if (!this.opponents) {
      const result: Result = await getOpponents();
      runInAction(() => {
        if (result.isSuccess) {
          this.opponents = [...result.result];
        } else {
          this.opponents = [];
        }
      });
    }
  };

  loadBrandAdvertisers = async () => {
    const result = await getOfferBrandAdvertisers();
    runInAction(() => {
      if (result.isSuccess) {
        this.brandAdvertisers = [...result.result];
      } else {
        this.brandAdvertisers = [];
      }
    });
  };

  loadAffiliates = async (name = "") => {
    this.affiliates.updateLoading(true);
    const result = await getAffiliates({
      page: this.affiliates.pagination.page,
      pageSize: this.affiliates.pagination.pageSize,
      name,
    });

    runInAction(() => {
      if (result && result.isSuccess) {
        this.affiliates.updateValue(result.result);
        this.affiliates.pagination.updateTotalCount(result.pagination.totalCount);
      } else {
        this.affiliates.updateValue([]);
        this.affiliates.pagination.updateTotalCount(0);
      }
      this.affiliates.updateLoading(false);
    });
  };

  loadOpponentsAffiliates = async (id: number, name = "") => {
    this.opponentsAffiliates.updateLoading(true);
    const result = await getOpponentsAffiliates({
      id,
      page: this.opponentsAffiliates.pagination.page,
      pageSize: this.opponentsAffiliates.pagination.pageSize,
      name,
    });

    runInAction(() => {
      if (result && result.isSuccess) {
        this.opponentsAffiliates.updateValue(result.result);
        this.opponentsAffiliates.pagination.updateTotalCount(result.pagination.totalCount);
      } else {
        this.opponentsAffiliates.updateValue([]);
        this.opponentsAffiliates.pagination.updateTotalCount(0);
      }
      this.opponentsAffiliates.updateLoading(false);
    });
  };

  reset = () => {
    this.affiliates.reset();
    this.opponentsAffiliates.reset();
    this.offers = null;
    this.timeZone = null;
    this.opponents = null;
    this.brandAdvertisers = null;
  };
}
