import { makeAutoObservable, runInAction, computed } from "mobx";

import { StatisticAffiliatesValue, StatisticAffiliatesPreviewValue } from "../model";
import { getFormatStatisticOffersAndAffiliatesValue } from "../utils";

import { StatisticsAffiliatesApi } from "shared/api";
import { PaginationStore } from "shared/store/Pagination";
import { SortingStore } from "shared/store/Sorting";

const { getData } = StatisticsAffiliatesApi();

export class StatisticAffiliatesStore {
  pagination = new PaginationStore(10);
  sorting = new SortingStore("id", 4);
  data: StatisticAffiliatesValue[] | null = null;
  summary: StatisticAffiliatesValue | null = null;
  affiliateId = "";
  loading = true;

  constructor() {
    makeAutoObservable(this);
  }

  get formatData(): StatisticAffiliatesPreviewValue[] {
    if (this.data) {
      return this.data.map((item, i) => {
        const children = item.offers?.map((offer, j) =>
          getFormatStatisticOffersAndAffiliatesValue({
            ...offer,
            id: `(${offer.id}) ${offer.name}`,
            key: `affiliate-offer-${item.id}-${offer.id}-${j}`,
          }),
        );
        return {
          ...getFormatStatisticOffersAndAffiliatesValue({
            ...item,
            id: String(item.id),
            key: `affiliate-${item.id}-${i}`,
          }),
          children,
        };
      });
    }
    return [];
  }

  get formatSummary(): StatisticAffiliatesPreviewValue | undefined {
    if (this.summary) {
      return getFormatStatisticOffersAndAffiliatesValue({ ...this.summary, id: "Всего", key: "affiliates-summary" });
    }
  }

  loadData = async (filters: Record<string, any>) => {
    this.loading = true;
    const result = await getData({
      ...filters,
      affiliateId: this.affiliateId,
      page: this.pagination.page,
      pageSize: this.pagination.pageSize,
      sortParam: this.sorting.sortParam,
      sortDirection: this.sorting.sortDirection || null,
    });
    runInAction(() => {
      if (result.isSuccess) {
        this.data = [...result.result.items];
        this.summary = this.data.length ? { ...result.result.summary } : null;
        this.pagination.updateTotalCount(result.result.pagination.totalCount);
      } else if (result.isError) {
        this.data = [];
        this.summary = null;
        this.pagination.updatePage(1);
        this.pagination.updateTotalCount(0);
      }
      this.loading = false;
    });
  };

  updateAffiliateId = (affiliateId: string) => {
    this.affiliateId = affiliateId;
  };
}
