import { makeAutoObservable } from "mobx";

import { DictionaryValue } from "../model";

import { PaginationStore } from "shared/store/Pagination";
import { DefaultOption } from "shared/ui/Select";

export class DictionaryValueStore {
  loading = false;
  value: DictionaryValue = null;
  pagination: PaginationStore;

  constructor(pageSize = 100) {
    makeAutoObservable(this);

    this.pagination = new PaginationStore(pageSize);
  }

  updateValue = (value: DefaultOption[]) => {
    if (this.value && this.pagination.page !== 1) {
      this.value = [...this.value, ...value];
    } else {
      this.value = [...value];
    }
  };

  updateLoading = (loading: boolean) => {
    this.loading = loading;
  };

  reset = () => {
    this.value = null;
    this.loading = false;
    this.pagination.updatePage(1);
  };
}
