import { runInAction, makeAutoObservable, computed } from "mobx";

import { UserProps } from "./model";

import { UserApi } from "shared/api";
import { Result } from "shared/api/request";
import { NewPassword } from "shared/api/User/types";

const { getUser, editPassword, logoutUser } = UserApi();

export class UserStore {
  user: UserProps | undefined = undefined;
  loading = false;

  constructor() {
    makeAutoObservable(this);
    if (this.user === undefined) {
      this.loading = true;
      this.load();
    }
  }

  get login(): string {
    return this.user?.login || "";
  }

  get fullName(): string {
    if (this.user && this.user.id) {
      if (this.user.name) {
        return `(${this.user.id}) ${this.user.name}`;
      }
      if (this.user.login) {
        return `(${this.user.id}) ${this.user.login}`;
      }
    }
    return "";
  }

  load = async () => {
    const result: Result = await getUser();
    runInAction(() => {
      if (result.isSuccess) {
        this.user = { ...result.result };
        this.loading = false;
      }
    });
  };

  updatePassword = async (data: NewPassword) => {
    return await editPassword(data);
  };

  logout = async () => {
    return await logoutUser();
  };
}
