import { Navigate, Route, createRoutesFromElements } from "react-router-dom";
import uniqid from "uniqid";

import { MainLayout } from "app/layouts/MainLayout";

export const mainRoutes = createRoutesFromElements(
  <Route element={<MainLayout />}>
    <Route id={uniqid()} path="/" element={<Navigate to="/dashboard" />} />
    <Route
      id={uniqid()}
      key={uniqid()}
      path="/dashboard"
      lazy={async () => {
        const { DashboardProvider } = await import("app/providers/DashboardProvider");
        const { DashboardPage } = await import("pages/DashboardPage");
        return {
          Component: () => (
            <DashboardProvider>
              <DashboardPage />
            </DashboardProvider>
          ),
        };
      }}
    />
    <Route
      id={uniqid()}
      key={uniqid()}
      path="/offers"
      lazy={async () => {
        const { OffersProvider } = await import("app/providers/OffersProvider");
        const { OffersPage } = await import("pages/OffersPage");
        return {
          Component: () => (
            <OffersProvider>
              <OffersPage />
            </OffersProvider>
          ),
        };
      }}
    />
    StatisticDailyPage
    <Route id={uniqid()} key={uniqid()} path="/statistics" element={<Navigate to="/statistics/days" />} />
    <Route id={uniqid()} key={uniqid()} path="/statistics">
      <Route
        id={uniqid()}
        key={uniqid()}
        path="days"
        lazy={async () => {
          const { StatisticDaysProvider } = await import("app/providers/StatisticsProviders");
          const { StatisticDaysPage } = await import("pages/StatisticDaysPage");
          return {
            Component: () => (
              <StatisticDaysProvider>
                <StatisticDaysPage />
              </StatisticDaysProvider>
            ),
          };
        }}
      />
      <Route
        id={uniqid()}
        key={uniqid()}
        path="offers"
        lazy={async () => {
          const { StatisticOffersProvider } = await import("app/providers/StatisticsProviders");
          const { StatisticOffersPage } = await import("pages/StatisticOffersPage");
          return {
            Component: () => (
              <StatisticOffersProvider>
                <StatisticOffersPage />
              </StatisticOffersProvider>
            ),
          };
        }}
      />
      <Route
        id={uniqid()}
        key={uniqid()}
        path="affiliates"
        lazy={async () => {
          const { StatisticAffiliatesProvider } = await import("app/providers/StatisticsProviders");
          const { StatisticAffiliatesPage } = await import("pages/StatisticAffiliatesPage");
          return {
            Component: () => (
              <StatisticAffiliatesProvider>
                <StatisticAffiliatesPage />
              </StatisticAffiliatesProvider>
            ),
          };
        }}
      />
      <Route
        id={uniqid()}
        key={uniqid()}
        path="opponents"
        lazy={async () => {
          const { StatisticOpponentsProvider } = await import("app/providers/StatisticsProviders");
          const { StatisticOpponentsPage } = await import("pages/StatisticOpponentsPage");
          return {
            Component: () => (
              <StatisticOpponentsProvider>
                <StatisticOpponentsPage />
              </StatisticOpponentsProvider>
            ),
          };
        }}
      />
      <Route
        id={uniqid()}
        key={uniqid()}
        path="opponents/:id"
        lazy={async () => {
          const { StatisticOpponentAffiliatesProvider } = await import("app/providers/StatisticsProviders");
          const { StatisticOpponentAffiliatesPage } = await import("pages/StatisticOpponentAffiliatesPage");
          return {
            Component: () => (
              <StatisticOpponentAffiliatesProvider>
                <StatisticOpponentAffiliatesPage />
              </StatisticOpponentAffiliatesProvider>
            ),
          };
        }}
      />
    </Route>
    <Route
      id={uniqid()}
      key={uniqid()}
      path="/profile/edit-password"
      lazy={async () => {
        const { EditUserPasswordPage } = await import("pages/EditUserPasswordPage");
        return { Component: EditUserPasswordPage };
      }}
    />
    <Route
      id={uniqid()}
      key={uniqid()}
      path="*"
      lazy={async () => {
        const { Page404 } = await import("pages/Page404");
        return { Component: Page404 };
      }}
    />
  </Route>,
);
