import React, { FC, useEffect, useRef, MouseEventHandler, useState } from "react";

import cn from "classnames";
import { CSSTransition } from "react-transition-group";
import { useEventListener } from "usehooks-ts";

import { DrawerProvider } from "./context";
import classes from "./Drawer.module.scss";
import { DrawerProps, DrawerContextValue } from "./Drawer.types";

import { BasePortal } from "shared/ui/BasePortal";

const portalNode = document.createElement("div");
portalNode.setAttribute("id", "drawer-portal");

export const Drawer: FC<DrawerProps> = ({
  children,
  className,
  open = false,
  theme = "light",
  position = "left",
  setOpen,
}) => {
  const ref = useRef(null);
  const [height, setHeight] = useState(window.visualViewport?.height ?? 0);
  const closeDrawer = () => setOpen && setOpen(false);
  const onClickHandler: MouseEventHandler<HTMLDivElement> = (e) => {
    if (e.target === ref.current) {
      closeDrawer();
    }
  };
  const ctx: DrawerContextValue = {
    close: closeDrawer,
  };

  useEventListener("keydown", (e: KeyboardEvent) => {
    if (e.key === "Escape") {
      closeDrawer();
    }
  });

  useEventListener("resize", () => {
    if (window.visualViewport?.height) {
      setHeight(window.visualViewport?.height);
    }
  });

  useEffect(() => {
    if (open) {
      document.body.classList.add(classes.overflow);
    } else {
      document.body.classList.remove(classes.overflow);
    }
  }, [open]);

  return (
    <BasePortal portalNode={portalNode}>
      <CSSTransition
        in={open}
        timeout={500}
        nodeRef={ref}
        classNames={{
          enter: classes[`drawer-enter`],
          enterActive: classes[`drawer-enter-active`],
          exit: classes[`drawer-exit`],
          exitActive: classes[`drawer-exit-active`],
        }}
        unmountOnExit
      >
        <DrawerProvider value={ctx}>
          <div
            data-testid="drawer-test"
            id="drawer"
            ref={ref}
            className={cn(classes.container, classes[theme])}
            onClick={onClickHandler}
            style={{ height: height - 50 }}
          >
            <div data-testid="drawer-content-test" className={cn(className, classes.content, classes[position])}>
              {children}
            </div>
          </div>
        </DrawerProvider>
      </CSSTransition>
    </BasePortal>
  );
};
